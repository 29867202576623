import App from '@/app'
import { Collapse } from '@/components'

/**
 * Module namespace
 * @required
 * @type {String}
 */
const namespace = 'footer'

/**
 * Deinfe module
 * @member App.module
 * @type {Object}
 */
App.module(namespace, {
  /**
   * Module constructor
   * @constructor
   */
  construct() {
    // Close footer collapsibles
    App.$on.initStateChange(() => this.collapseAll())
    // Offset collapsibles using footer height
    $(this.selector.collapsible).on(this.event.collapseShow, e => this.setFooterBoundingRect(e))
  },

  /**
   * Module data
   * @type {Object}
   */
  data: {
    selector: {
      footer: '#footer',
      collapsible: '#footer > .collapse',
    },
    event: {
      collapseShow: Collapse.$events.show
    }
  },

  /**
   * Module methods
   * @type {Object}
   */
  methods: {
    setFooterBoundingRect(e) {
      $(e.currentTarget).css('bottom', $(this.selector.footer).outerHeight())
    },

    collapseAll() {
      Collapse.hideAll($(this.selector.footer)[0])
    },
  },
})