import App from '@/app'
import Stickybits from 'stickybits'

/**
 * Component namespace
 * @required
 * @type {String}
 */
const namespace = 'sticky'

/**
 * Deinfe component
 * @member App.module
 * @type {Object}
 */
App.module(namespace, {
  /**
   * Component constructor
   * @constructor
   */
  construct() {
    App.$on.initStateChange(() => this.destroy())
       .$on.newPageReady(x => this.dataApiInit(x))
  },

  /**
   * Component data
   * @type {Object}
   */
  data: {
    selector: {
      element: '[data-sticky]'
    }
  },

  /**
   * Component methods
   * @type {Object}
   */
  methods: {
    dataApiInit(context) {
      $(this.selector.element, context).each((index, element) => {
        const $element = $(element)
        const top = parseInt($(element).css('top'), 10)||0
        Stickybits(element, {stickyBitStickyOffset:top})
      })
    },

    destroy() {
      // $(window).off(this.event.resize)
    }
  }
})