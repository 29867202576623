import App from '@/app'
import 'jquery.easing'

const NAME         = 'collapse'
const DATA_KEY     = `wl.${NAME}`
const EVENT_KEY    = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Event = {
  show: `show${EVENT_KEY}`,
  shown: `shown${EVENT_KEY}`,
  hide: `hide${EVENT_KEY}`,
  hidden: `hidden${EVENT_KEY}`,
  clickDataApi: `click${EVENT_KEY}${DATA_API_KEY}`,
}

const ClassName = {
  active : 'active',
  show: 'show',
  collapse: 'collapse'
}

const Selector = {
  dataApiToggle: `[data-toggle="${NAME}"]`,
  collapse: `.${ClassName.collapse}`,
  actives: `.${ClassName.show}, .${ClassName.active}`
}

const Defaults = {
  parent : '',
  easeIn: 'easeOutExpo',
  easeOut: 'linear',
  speedIn: 450,
  speedOut: 250
}

const DefaultsType = {
  parent : '(string|element)',
  easeIn: 'string',
  easeOut: 'string',
  speedIn: 'number',
  speedOut: 'number'
}

export default class Collapse extends App.Component {
  /**
   * Define @public properties
   * @return {Object}
   */
  static get $props() {
    return {
      namespace: DATA_KEY, // namespaced component
      eventNs: EVENT_KEY,  // namespaced events
      defaults: Defaults   // default component options
    }
  }

  /**
   * Define @public event names
   * @return {Object}
   */
  static get $events() {
    return Event
  }

  /**
   * Define @public default options
   * @return {Object}
   */
  static get $defaults() {
    return Defaults
  }

  /**
   * Type checking options in constructor
   * @return {Object}
   */
  static get $types() {
    return DefaultsType
  }

  constructor(element, options) {
    super(element, options, NAME)
    this._parent = $(this.$options.parent)
  }

  toggle() {
    this[$(this.$element).hasClass(ClassName.active) ? 'hide' : 'show']()
  }

  show() {
    const $target = $(this.$element)
    let actives

    if (this._parent.length) {
      actives = this._getGroupActives()
    }

    const startEvent = $.Event(Event.show)
    $target.trigger(startEvent)
    if (startEvent.isDefaultPrevented()) {
      return
    }

    if (actives) {
      actives.each((index, element) => {
        const data = $(element).data(DATA_KEY)
        data && data.hide()
      })
    }
    
    const complete = () => {
      $target
        .addClass(ClassName.show)
        .trigger(Event.shown)
    }

    $target
      .addClass(ClassName.active)
      .slideDown(this.$options.speedIn, this.$options.easeIn, complete)
  }

  hide() {
    const $target = $(this.$element)

    const startEvent = $.Event(Event.hide)
    $target.trigger(startEvent)
    if (startEvent.isDefaultPrevented()) {
      return
    }

    const complete = () => {
      $target
        .removeClass(ClassName.active)
        .trigger(Event.hidden)
    }

    $target
      .removeClass(ClassName.show)
      .slideUp(this.$options.speedOut, this.$options.easeOut, complete)
  }

  destroy() {
    $.removeData(this.$element, DATA_KEY)
    $(this.$element).off(EVENT_KEY)
    this.$element = null
    this._parent = null
  }

  isActive() {
    return $(this.$element).is(Selector.actives)
  }

  _getGroupActives() {
    const element = this._parent.find(Selector.collapse).filter(Selector.actives).not(this.$element)
    return element.length ? element : null
  }

  static hideAll(scope) {
    let ie = Collapse.getInstance(scope)

    if (!ie.length) return 0;

    ie = ie.filter(Selector.actives).each((_, element) => {
      const context = $(element).data(DATA_KEY)
      context && context.hide()
    })

    return ie.length
  }

  static getInstance(scope, enabled = true) {
    if (!scope) {
      scope = document.body
    }

    return $(Selector.collapse, scope).filter((_, element) => {
      if (!enabled) return true
      const ie = $(element).data(DATA_KEY)
      return ie && typeof ie === 'object'
    })
  }
}

App.$document.on(Event.clickDataApi, Selector.dataApiToggle, e => {
  // preventDefault only for <a> elements (which change the URL) not inside the collapsible element
  if (e.currentTarget.tagName.toLowerCase() === 'a') {
    e.preventDefault()
  }

  const $trigger = $(e.currentTarget)
  const $targets = App.Utils.getTargetSelector(e.currentTarget, true)

  $targets.each((index, element) => {
    const $target = $(element)
    let data = $target.data(DATA_KEY)
    if (!data) {
      data = new Collapse(element, $trigger.data())
      $target.data(DATA_KEY, data)
    }
    data.toggle()
  })
})