import locutusUniqid from 'locutus/php/misc/uniqid'
import detectIt from 'detect-it'

/**
 * -------------------------------------------------------------------
 * Utilities
 * -------------------------------------------------------------------
 */

/**
 * Utility functions
 * @member App
 * @type   {Object}
 */
const Util = {
  /**
   * Property is programatically set to true||false and subsequently
   * anything that invokes a lookup returns the boolean
   * 
   * @type {boolean}
   */
  isScrolling: false,

  /**
   * Gets a prefixed unique identifier based on the current time in microseconds
   * @type   {Function}
   * @return {boolean}
   */
  uniqid: locutusUniqid,

  /**
   * Detects device state using "rafrex/detect-it"
   * detectIt.deviceType === 'mouseOnly' / 'touchOnly' / 'hybrid'; // the device type
   * detectIt.passiveEvents === true; // the browser supports passive event listeners
   * detectIt.hasMouse === true; // the deviceType is mouseOnly or hybrid
   * detectIt.hasTouch === true; // the browser supports the touch events api, and the deviceType is touchOnly or hybrid
   * detectIt.primaryInput === 'mouse' / 'touch'; // the primary input type
   * @return {Object}
   */
  detect: detectIt,

  detectIE() {
    const ua = window.navigator.userAgent
    const msie = ua.indexOf('MSIE ')

    if (msie > 0)
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  
    const trident = ua.indexOf('Trident/')
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:')
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
    }
  
    const edge = ua.indexOf('Edge/')
    if (edge > 0)
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)

    return false
  },

  /**
   * Returns a jQuery instance an HTMLElement interface
   * 
   * @param  {(Element)|string} element The node element or string to convert
   * @return {Element}
   */
  $(element) {
    return element instanceof jQuery ? element : $(element)
  },

  /**
   * Fixing the JavaScript typeof operator.
   * @param  {*} obj The value to test our typeOf operator
   * @return {string}
   */
  toType(obj) {
    return {}.toString.call(obj).match(/\s([a-z]+)/i)[1].toLowerCase()
  },

  /**
   * Check if obj is a function...
   * In some browsers, typeof returns "function" for HTML <object> elements
   * (i.e., `typeof document.createElement( "object" ) === "function"`)
   * We don't want to classify *any* DOM node as a function
   * 
   * @param  {*} obj The "function" of which to be verified
   * @return {boolean}
   */
  isFunction(obj) {
    return typeof obj === 'function' && typeof obj.nodeType !== 'number'
  },

  /**
   * Returns an integer value which specifies the type of the node
   * @param  {*} obj 
   * @return {number}
   */
  isElement(obj) {
    return (obj[0] || obj).nodeType
  },

  /**
   * 
   * @param {string} componentName 
   * @param {Object} config 
   * @param {Object} configTypes 
   */
  typeCheckConfig(componentName, config, configTypes) {
    for (const property in configTypes) {
      if (Object.prototype.hasOwnProperty.call(configTypes, property)) {
        const expectedTypes = configTypes[property]
        const value = config[property]
        const valueType = value && Util.isElement(value) ? 'element' : Util.toType(value)
        if (!new RegExp(expectedTypes).test(valueType)) {
          throw new Error(
            `${componentName.toUpperCase()}: ` +
            `Option "${property}" provided type "${valueType}" ` +
            `but expected type "${expectedTypes}".`)
        }
      }
    }
  },

  /**
   * Mixins can be properly merged with enumerables which
   * allow our objects to be more "defined".
   * 
   * @param  {Object} obj   The object on which to copy enumerables
   * @param  {Object} props The properties which will be applied to the new object
   * @return {Object}       The object that was passed to the function
   */
  extend(...sources) {
    let newObj = Object.create(Object.getPrototypeOf(sources.slice(1)[0]))
    sources.forEach(source => {
      Object.getOwnPropertyNames(source).forEach(prop => {
        const descriptor = Object.getOwnPropertyDescriptor(source, prop)
        Object.defineProperty(newObj, prop, descriptor)
      })
    })
    return newObj
  },

  /**
   * Return hash of a string
   * 
   * @param  {string} str 
   * @return {string}
   */
  hash(str) {
    let hash = 0, i, char
    if (!str.length) return hash
    for (i = 0, l = str.length; i < l; i++) {
      char = s.charCodeAt(i)
      hash = ((hash << 5) - hash) + char
      hash |= 0 // Convert to 32bit integer
    }
    return hash
  },

  /**
   * Return segment from URL
   * 
   * @param  {string}  pathname   Location pathname
   * @param  {number}  index      Segment index
   * @param  {boolean} localized  [In/Ex]clude locale in lookup
   * @return {string|boolean}
   */
  getSegment(pathname, index, localized = true) {
    let segments = pathname.split('/').filter(String)
    if (segments.length) {
      if (localized && segments[0].toLowerCase() === "fr") {
        segments.shift()
      }
    }
    if (!segments.length) return false
    if (index) {
      index--
      return segments[index]||false
    }
    return segments
  },

  /**
   * Shorthand method to retrieve (first) segment from a URL.
   * 
   * @param  {string}  pathname  Path of the URL
   * @param  {boolean} localized [In/Ex]clude locale in lookup
   * @return {string|boolean}
   */
  firstSegment(pathname, localized = true) {
    return Util.getSegment(pathname, 1, localized)
  },

  /**
   * Returns a new function that, when invoked, invokes `func` at most once per `wait` milliseconds.
   * Example: throttle(function() {}, 250)
   * @param  {Function} func  Function to wrap
   * @param  {number}   wait  Number of milliseconds that must elapse between `func` invocations
   * @return {Function}       A new function that wraps the `func` function passed in
   */
  throttle(func, wait) {
    var ctx, args, rtn, timeoutID; // caching
    var last = 0;

    return function throttled () {
      ctx = this;
      args = arguments;
      var delta = new Date() - last;
      if (!timeoutID)
        if (delta >= wait) call();
        else timeoutID = setTimeout(call, wait - delta);
      return rtn;
    };

    function call () {
      timeoutID = 0;
      last = +new Date();
      rtn = func.apply(ctx, args);
      ctx = null;
      args = null;
    }
  },

  /**
   * [description]
   * @param {Element} element       [description]
   * @param {Element} returnElement [description]
   */
  getTargetSelector(element, returnElement = false) {
    const $target = Util.$(element)
    let selector = $target.data('target')
    if (!selector) {
      selector = $target.attr('href')
      selector = selector && selector.replace(/.*(?=#[^\s]*$)/, '')
    }
    if (returnElement) return $(selector)
    return selector
  },

  /**
   * A reflow computes the layout of the page. A reflow on an element recomputes 
   * the dimensions and position of the element, and it also triggers further 
   * reflows on that element’s children, ancestors and elements that appear 
   * after it in the DOM. Then it calls a final repaint. Reflowing is very 
   * expensive, but unfortunately it can be triggered easily.
   * @param {Element} element 
   */
  reflow(element) {
    return element.offsetHeight
  },

  /**
   * Scroll to element and return Promise. Options as follows:
   * ---------------------------------------------------------------------------
   *  scope       [string]  Element to scroll (default: html,body)
   *  offset      [number]  Computed offset to subtract from element offset
   *  speed       [number]  Speed of scroll animation
   *  continuous  [boolean] Set to true to disregard wait for scroll to complete
   * ---------------------------------------------------------------------------
   * @param  {(Element|string|number)} element The target element or selector to scroll to
   * @param  {Object} opts Additional options (see method description)
   * @return {Promise}
   */
  scrollTo(element, opts) {
    opts = $.extend({
      "scope": 'html, body',
      "offset": 0,
      "speed": 400,
      "continuous": false
    }, opts||{})
    // Promise rejected because we are busy scrolling already
    if (!opts.continuous && Util.isScrolling) return $.Deferred().reject('Busy scrolling!')
    // Scroll and return Promise
    let offset = false
    if (typeof element === 'number') {
      offset = element
    }
    else {
      const $target = Util.$(element)
      if ($target.length)
        offset = Math.abs($target.offset().top - opts.offset)
    }
    Util.isScrolling = true
    return Util.$(opts.scope).stop(true).animate({scrollTop: offset}, opts.speed).promise().then(() => {
      Util.isScrolling = false
    })
  },

  /**
   * 
   * @param  {Array}  arr       The array to revise
   * @param  {number} oldIndex  The current index of the item to move
   * @param  {number} newIndex  The new index to move item to
   * @return {Array}
   */
  arrayItem(arr, oldIndex, newIndex) {
    if (Array.isArray(arr)) {
      if (newIndex >= arr.length) {
        let k = newIndex - arr.length
        while ((k--) + 1) {
          arr.push(undefined)
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
    }
    return arr
  },

  transitionEnd() {
    let el = document.createElement('fakeelement')
    const transitions = {
      'transition'      : 'transitionend',
      'OTransition'     : 'oTransitionEnd',
      'MozTransition'   : 'transitionend',
      'WebkitTransition': 'webkitTransitionEnd'
    }
    let t = Object.keys(transitions).find(x => el.style[x] !== undefined)
    return typeof t === 'string' ? transitions[t] : false
  },

  /**
   * Formats a deprecation message i.e. console output
   * 
   * @param  {string} functionName 
   * @param  {string} noticeMessage 
   * @param  {string} namespaced
   * @return {string}
   */
  deprecated(functionName, noticeMessage = '', namespaced = '') {
    let str = 'DEPRECATED: '
    str += namespaced.length ? `${namespaced}.${functionName}` : functionName
    if (noticeMessage.length) str += ` ~ ${noticeMessage}`
    return str
  }
}

// Protoype extensions
if (typeof String.prototype.toCamelCase !== 'function') {
  String.prototype.toCamelCase = function() {
    return this
      .replace(/^[_.\- ]+/, '')
      .toLowerCase()
      .replace(/[_.\- ]+(\w|$)/g, (m, p1) => p1.toUpperCase())
  }
}

export default Util