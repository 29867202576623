import App from '@/app'
import Scrollbar from 'smooth-scrollbar'
import 'jquery.easing'

const NAME = 'dropdown'
const DATA_KEY = `sc.${NAME}`
const EVENT_KEY = `.${DATA_KEY}`

const Event = {
  hide: `hide${EVENT_KEY}`,
  hidden: `hidden${EVENT_KEY}`,
  show: `show${EVENT_KEY}`,
  shown: `shown${EVENT_KEY}`,
  change: `change${EVENT_KEY}`,
  changed: `changed${EVENT_KEY}`,
  click: `click${EVENT_KEY}`,
  clickDataApi: `click${EVENT_KEY}.data-api`
}

const ClassName = {
  activeState: 'active',
  visibleState: 'show'
}

const Selector = {
  dataApiToggle: '[data-dropdown]',
  element: '.dropdown',
  selectInput: '.dropdown__select'
}

const Defaults = {
  easeIn: 'easeInOutExpo',
  easeOut: 'linear',
  speedIn: 450,
  speedOut: 250
}

export default class Dropdown extends App.Component {
  /**
   * Define @public properties
   * @return {Object}
   */
  static get $props() {
    return {
      namespace: DATA_KEY, // namespaced component
      eventNs: EVENT_KEY,  // namespaced events
      defaults: Defaults   // default component options
    }
  }

  /**
   * Define @public event names
   * @return {Object}
   */
  static get $events() {
    return Event
  }

  /**
   * Define @public default options
   * @return {Object}
   */
  static get $defaults() {
    return Defaults
  }

  constructor(element, options) {
    super(element, options, NAME)
    this.$parent = $(this.$element).parent()
    this.$target = $(this.$options.dropdown).get(0)
  }

  toggle() {
    const $target = $(this.$target)
    const isActive = $target.hasClass(ClassName.activeState)

    if (!this.animating) {
      this.constructor.hideAll()
      if (!isActive) this.show()
    }
  }

  show() {
    const relatedTarget = {
      relatedTarget: this.$element
    }
    const showEvent = $.Event(Event.show, relatedTarget)
    const $target = $(this.$target)

    $target.trigger(showEvent)

    if (showEvent.isDefaultPrevented()) return

    $target.addClass(ClassName.activeState)
    $(this.$element).addClass(ClassName.activeState)

    this.animating = true
    $target.slideDown(this.$options.speedIn, this.$options.easeIn, () => {
      this.animating = false
      $target.find('.scroll').each((index, element) => Scrollbar.init(element))
      $target
        .addClass(ClassName.visibleState)
        .trigger($.Event(Event.shown, relatedTarget))
    })
  }

  hide() {
    const relatedTarget = {
      relatedTarget: this.$element
    }
    const hideEvent = $.Event(Event.hide, relatedTarget)
    const $target = $(this.$target)

    $target.trigger(hideEvent)

    if (hideEvent.isDefaultPrevented()) return

    $(this.$element).removeClass(ClassName.activeState)

    this.animating = true
    $target
      .removeClass(ClassName.visibleState)
      .slideUp(this.$options.speedOut, this.$options.easeOut, () => {
        this.animating = false
        $target.find('.scroll').each(function() {
          Scrollbar.destroy(this)
        })
        $target
          .removeClass(ClassName.activeState)
          .trigger($.Event(Event.hidden, relatedTarget))
      })
  }

  destroy() {
    $.removeData(this.$element, DATA_KEY)
    $(this.$element).off(EVENT_KEY)
    this.$element = null
    this.$target = null
  }

  static hideAll(scope) {
    const ie = Dropdown.getInstance(scope)

    if (!ie.length) return 0

    ie.each((_, element) => {
      const context = $(element).data(DATA_KEY)
      if (context && $(context.$target).hasClass(ClassName.activeState)) {
        context.hide()
      }
    })

    return ie.length
  }

  static destroyAll(scope) {
    const ie = Dropdown.getInstance(scope)

    if (!ie.length) return 0

    ie.each((_, element) => {
      const context = $(element).data(DATA_KEY)
      context && context.destroy()
    })

    return ie.length
  }

  static getInstance(scope, enabled = true) {
    if (!scope) scope = document.body

    return $(Selector.dataApiToggle, scope).filter((_, element) => {
      if (!enabled) return true
      const ie = $(element).data(DATA_KEY)
      return ie && typeof ie === 'object'
    })
  }
}

App.$on
  .newPageReady(container => {
    $(container).on(Event.clickDataApi, Selector.dataApiToggle, e => {
      e.preventDefault()
      e.stopPropagation()
      let data = $(e.currentTarget).data(DATA_KEY)
      if (!data) {
        data = new Dropdown(e.currentTarget)
        $(e.currentTarget).data(DATA_KEY, data)
      }
      data.toggle()
    })
  })
  .$on.initStateChange(currentStatus => {
    const domObj = App.Barba.Pjax.Dom
    const element = domObj.getContainer()
    if (element && domObj.getNamespace(element) !== currentStatus.namespace)
      Dropdown.hideAll(element)
  })
