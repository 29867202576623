import App from '@/app'
import { Slider } from '@/components'

const NAMESPACE = 'home.index'

App.extend(NAMESPACE, {
  methods: {
    toggleState(state = false) {
      return this.using('module/navbar').invert(state).translucent(state)
    }
  },
  transition: {
    onEnter() {
      // Feature slider
      Slider.instance($('#featured .slider'), {
        arrows: false,
        autoplay: true,
        pauseOnHover: false,
        autoplaySpeed: 4000
      }).init()

      // toggle navbar translucency
      App.$window.on(`scroll.offset.${NAMESPACE} resize.offset.${NAMESPACE}`, App.Utils.throttle(() => {
        const offset = Math.max(App.$window.height() - (this.using('module/navbar').$headerHeight / 2)) - App.$window.scrollTop()
        this.toggleState(Boolean(offset >= 0))
      }, 300))
      this.toggleState(true)
    },
    onLeave() {
      this.toggleState(false)
      App.$window.off(`.${NAMESPACE}`)
    }
  }
})