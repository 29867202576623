/**
 * -------------------------------------------------------------------
 * Barba
 * -------------------------------------------------------------------
 */
import Barba from 'barba.js'
import 'imagesloaded'

// Barba init wrapper
Barba.init = () => {
  Barba.Progress.show()
  $(() => Barba.Pjax.start())
}

/**
 * Barba defaults
 * @config
 */
Barba.Pjax.Dom.wrapperId = 'app'
Barba.Pjax.Dom.containerClass = 'app-container'
Barba.Pjax.Dom.dataNamespace = 'view'
Barba.Pjax.ignoreClassLink = 'no-xhr'
Barba.Utils.xhrTimeout = 15000
Barba.BaseTransition.animationSpeed = 350

/**
 * Barba transition callback override
 * 
 * @member Barba.BaseTransition
 * @type   {Function}
 */
Barba.BaseTransition.done = function() {
  $(this.oldContainer).remove()
  $(this.newContainer).css('visibility', 'visible')
  this.deferred.resolve()
}

/**
 * Transition in animation
 * 
 * @member Barba.BaseTransition
 * @type   {Function}
 * @param  {HTMLElement} $element
 * @param  {Function}    done
 */
Barba.BaseTransition.animateIn = function($element, done) {
  $element.imagesLoaded().always(() => {
    Barba.Progress.hide()
    $element.css({
      visibility : 'visible',
      opacity : 0
    })
    .animate({ opacity: 1 }, Barba.BaseTransition.animationSpeed, () => {
      done && done()
    })
  })
}

/**
 * Barba init hijack to execute transition on initial load
 * @member Barba.Pjax
 * @type   {Function}
 * @return {Object}
 */
Barba.Pjax.init = function () {
  const container = this.Dom.getContainer()
  const wrapper = this.Dom.getWrapper()

  $(wrapper).attr('aria-live', 'polite')

  this.History.add(
    this.getCurrentUrl(),
    this.Dom.getNamespace(container)
  )

  Barba.Dispatcher.trigger('initStateChange', this.History.currentStatus())

  Barba.Dispatcher.trigger('newPageReady',
    this.History.currentStatus(),
    {},
    container,
    this.Dom.currentHTML
  )

  /** begin:hijack */
  const transition = Object.create(this.getTransition())
  this.transitionProgress = true
  transition.init(null, $.Deferred().resolve(container).promise()).then(() => {
    this.transitionProgress = false
    Barba.Dispatcher.trigger('transitionCompleted', this.History.currentStatus())
  })
  /** end:hijack */

  this.bindEvents()
}

/**
 * Barba transition
 * @member Barba.Pjax
 * @type   {Function}
 * @return {Object}
 */
Barba.Pjax.getTransition = function() {
  return Barba.BaseTransition.extend({
    start() {
      Promise
        .all([this.newContainerLoading, this.out()])
        .then(this.in.bind(this))
    },
    in() {
      const $el = $(this.newContainer)
      if (this.oldContainer) {
        $(this.oldContainer).hide()
      }
      $(window).scrollTop(0)
      this.animateIn($el, $.proxy(this.done, this))
    },
    out() {
      if (this.oldContainer) {
        return $(this.oldContainer).animate({ opacity: 0 }, Barba.BaseTransition.animationSpeed).promise()
      }
      return $.Deferred().resolve().promise()
    }
  })
}

// Progress bar invoked when "linkClicked" event is emitted
Barba.Dispatcher.on('linkClicked', element => {
  const fromCache = Barba.Pjax.Cache.get(Barba.Pjax.getHref(element))
  if (typeof fromCache === 'undefined')
    Barba.Progress.show()
})


/**
 * Extend Barba with our custom progress bar
 * @type {Object}
 */
Barba.Progress = {
  Defaults: {
    id: 'pageLoader',
    className: 'progress-bar',
    activeState: 'active'
  },
  show() {
    if (!this.$element) {
      this.$element = $(document.createElement('div'))
        .attr('id', this.Defaults.id)
        .addClass(this.Defaults.className)
        .appendTo(document.body)
    }
    this.$element.show()
  },
  hide() {
    this.$element.fadeOut(250)
  },
}

export default Barba