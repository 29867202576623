import App from '@/app'
import { Slider } from '@/components'
import './navbar'
import './footer'
import './sticky'
import './readMore'
import './waypoints'
import './videoPlayer'

/**
 * Component namespace
 * @required
 * @type {String}
 */
const namespace = 'global'

/**
 * Deinfe component
 * @member App.module
 * @type {Object}
 */
App.module(namespace, {
  /**
   * Component data
   * @type {Object}
   */
  data: {
    className: {
      invertContentState: 'g-content--invert',
      whiteContentState: 'g-content--white',
      noScrollState: 'no-scroll'
    },
  },

  /**
   * Component methods
   * @type {Object}
   */
  methods: {
    /**
     * Global newPageReady event handler bound to current view container
     * @param {HTMLElement} context 
     */
    newPageReady(container) {
      // Scroll to element handler
      $(container).on('click.scrollTo.data-api', '[data-scrollto]', e => this.scrollTo(e))
      // init grid sliders
      this.initGridSliders(container)
    },

    /**
     * Scroll to element handler
     * @param {Event} e 
     */
    scrollTo(e) {
      const selector = $(e.currentTarget).data('scrollto')
      // Scroll to top?
      if (selector === 'top') {
        App.Utils.scrollTo(0)
        return
      }
      // Consider header height to calculate
      let offsets = [$(this.using('module/navbar').selector.header).outerHeight()]
      // Consider fixed elements to calculate
      const $fixedElements = $(this.using('module/sticky').selector.element)
      $fixedElements.length && $fixedElements.each((i, element) => offsets.push($(element).outerHeight(true)))
      // Calculate offset
      const offsetComputed = offsets.reduce((a, b) => a + b, 0)
      // Get the target element
      const $target = $(selector)
      $target.length && App.Utils.scrollTo($target, { offset: offsetComputed })
    },

    /**
     * Invert content
     * @param {Boolean} state 
     */
    invert(state = false) {
      $('body').toggleClass(this.className.invertContentState, Boolean(state))
    },

    /**
     * White content
     * @param {Boolean} state
     */
    white(state = false) {
      $('body').toggleClass(this.className.whiteContentState, Boolean(state))
    },

    toggleScroll(state = false) {
      $('body').toggleClass(this.className.noScrollState, Boolean(state))
    },

    /**
     * Initialize global .grid-slider carousels
     * @param {Element} container 
     */
    initGridSliders(container) {
      $('.slider--grid', container).each(function () {
        Slider.instance(this, Slider.$props.presets['related']).init()
      })
    }
  },

  /**
   * Component constructor
   * @constructor
   */
  construct() {
    App.$on.newPageReady(x => this.newPageReady(x))
  }
})