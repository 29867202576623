import App from "@/app";
import { Slider, Dropdown } from "@/components";
import "jquery-match-height";

const NAMESPACE = "syncs.index";

const Selector = {
  tiles: ".tile--sync",
  filterItem: "[data-filter]",
  resetFilter: '[data-filter = "reset"]',
  matchHeight: ".tile--sync  .tile__body",
  activeState: ".active",
  visibleWaypoint: ".waypoint--visible",
};

const ClassName = {
  activeState: "active",
  visibleWaypoint: "waypoint--visible",
};

App.extend(NAMESPACE, {
  data: {
    get $tiles() {
      return $("#all-syncs").find(Selector.tiles);
    },
    get activeFilter() {
      return this._activeFilter;
    },
    // setter and notifier
    set activeFilter({ type, value }) {
      this.activeFilterType = type;
      this._activeFilter = value;
      value !== null && this.updateFilterState();
    },
  },
  methods: {
    matchTileHeight(fn) {
      return $(Selector.matchHeight, this.container).matchHeight();
    },
    slidesToShow(slidesToShow, totalSlides) {
      return totalSlides < slidesToShow ? totalSlides : slidesToShow;
    },
    updateFilterState() {
      let filterDropdown;
      if (
        (filterDropdown = Dropdown.getInstance(
          $(".toolbar", this.container)
        )) &&
        filterDropdown.length
      ) {
        // hide active dropdown
        filterDropdown
          .filter(Selector.activeState)
          .data(Dropdown.$props.namespace)
          .hide();
      }
      // toggle tiles being shown/hidden
      if (this.activeFilterType === "reset") {
        // reset shows all tiles
        this.$tiles.show().addClass(ClassName.visibleWaypoint);
      } else {
        // filter tiles
        this.$tiles
          .removeClass(ClassName.visibleWaypoint)
          .not(`[data-filter-${this.activeFilterType}="${this.activeFilter}"]`)
          .hide()
          .end()
          .filter(
            `[data-filter-${this.activeFilterType}="${this.activeFilter}"]`
          )
          .show()
          .addClass(ClassName.visibleWaypoint);
      }
      // TODO: re-apply matching heights
      // this.matchTileHeight(ture)
      this.using("module/waypoints").refresh();
    },
    handleClick(event) {
      event.preventDefault();
      const target = $(event.currentTarget);
      const delegateTarget = event.delegateTarget;

      console.log("delegateTarget", event.delegateTarget);
      console.log("target", event.currentTarget);

      let [t, v] = target.data("filter").split(":");

      this.activeFilter = { type: t, value: v };
      // toggle reset filter
      this.applyFilter(target, delegateTarget);
    },
    applyFilter(target, delegateTarget) {
      const resetFilter = $(Selector.resetFilter, delegateTarget).css(
        "display",
        this.activeFilterType !== "reset" ? "block" : "none"
      );
      $(Selector.resetFilter, this.container)
        .not(resetFilter)
        .hide();
      // filter active state
      $(Selector.filterItem, this.container)
        .filter(Selector.activeState)
        .removeClass(ClassName.activeState);

      this.activeFilterType !== "reset" &&
        target.addClass(ClassName.activeState);
    },
  },
  transition: {
    onEnter() {
      console.log("syncs");
      const that = this;
      this.matchTileHeight();

      // init featured slider
      Slider.instance($("#featuredSyncsMedia"), {
        arrows: false,
        autoplay: true,
        pauseOnHover: false,
        autoplaySpeed: 4000,
        asNavFor: "#featuredSyncsMediaNav",
      }).init();

      const $sliderNav = $("#featuredSyncsMediaNav");
      const slideCount = $sliderNav.find(".slider__item").length;
      Slider.instance($sliderNav, {
        arrows: false,
        asNavFor: "#featuredSyncsMedia",
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              slidesToShow: that.slidesToShow(2, slideCount),
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: that.slidesToShow(3, slideCount),
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: that.slidesToShow(4, slideCount),
            },
          },
        ],
      }).init();

      // Category Filter
      const filters = [, $("")];
      $("#filterGenre, #filterArtist").on(
        "click",
        Selector.filterItem,
        (event) => this.handleClick(event)
      );
    },
    onEnterCompleted() {
      // listen to select menu chnges
      $(".dropdown__select--artists").on("change", (event) => {
        this.activeFilter = { type: "artist", value: event.target.value };
      });
      $(".dropdown__select--genre").on("change", (event) => {
        this.activeFilter = { type: "category", value: event.target.value };
      });
    },
    onLeave() {
      this.activeFilter = { value: null };
    },
  },
});
