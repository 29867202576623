import App from '@/app'
import { Dropdown } from '@/components'
import 'jquery-match-height'
import './entry'

const NAMESPACE = 'news.index'

const Selector = {
  tiles       : '.tile--news',
  filterItem  : '[data-filter]',
  resetFilter : '[data-filter="reset"]',
  filterScope : '#filterCategory'
}

const ServiceWorker = {
  initial  : 'service/news',
  category : 'service/news/category/<id>'
}

App.extend(NAMESPACE, {
  data: {
    get $tiles() {
      return $(Selector.tiles, this.container)
    },
    get activeFilter() {
      return this._activeFilter
    },
    // setter and notifier
    set activeFilter(val) {
      this._activeFilter = val
      val !== null && this.updateFilterState()
    }
  },
  methods: {
    matchTileHeight() {
      $(`${Selector.tiles} > a`, this.container).matchHeight()
      this.using('module/waypoints').refresh()
    },
    handleClick(event) {
      event.preventDefault()
      const target = $(event.currentTarget)
      this.activeFilter = target.data('filter')
      // toggle reset filter
      $(Selector.resetFilter, event.delegateTarget).css('display', this.activeFilter !== 'reset' ? 'block' : 'none')
      // filter active state
      $(`.active${Selector.filterItem}`, event.delegateTarget).removeClass('active')
      this.activeFilter !== 'reset' && target.addClass('active')
    },
    updateFilterState() {
      let filterDropdown
      if ((filterDropdown = Dropdown.getInstance($(`[data-dropdown="${Selector.filterScope}"]`).parent())) && filterDropdown.length) {
        // hide the dropdown
        filterDropdown.data(Dropdown.$props.namespace).hide()
      }
      // make xhr
      const xhr = ServiceWorker[ this.activeFilter !== 'reset' ? 'category' : 'initial' ].replace('<id>', this.activeFilter)
      App.Progress.show()
      $.get(xhr).then((res, status, xhr) => {
        const items = $(res).children()
        if (!items.length) xhr.reject()
        return items
      })
      .fail(() => { throw 'Well that went horribly wrong?' })
      .done(items => {
        $('#results').empty().append(items).imagesLoaded().always(() => {
          this.matchTileHeight()
          this.using('module/waypoints').make(this.container)
        })
      })
      .always(() => {
        App.Progress.hide()
        window.scrollY && window.scrollTo(0,0)
      })
    }
  },
  transition: {
    onEnter() {
      this.matchTileHeight()
    },
    onEnterCompleted() {
      this.matchTileHeight()
      $(Selector.filterScope).on('click', Selector.filterItem, event => this.handleClick(event))
    },
    onLeave() {
      this.activeFilter = null
    }
  }
})
