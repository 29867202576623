import App from '@/app'

const NAMESPACE = 'news.entry'

App.extend(NAMESPACE, {
  transition: {
    onEnter() {
      this.using('module/global').white(true)
    },
    onLeave() {
      this.using('module/global').white(false)
    },
  }
})