import App from "@/app";

const NAMESPACE = "neighbouring-rights-form.index";

App.extend(NAMESPACE, {
  transition: {
    onEnter() {
      this.using('module/navbar').invert(true)
      this.using('module/global').invert(true)
    },

    onLeave() {
      this.using('module/navbar').invert(false)
      this.using('module/global').invert(false)
    },
  },
});
