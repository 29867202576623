var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div id=\"modalContainer\" class=\"modal\">\n  <a role=\"button\" data-dismiss=\"modal\" class=\"modal__close\" tabindex=\"-1\">&times;</a>\n  <div class=\"modal__dialog\">\n    <div class=\"modal__body contained\">\n      <div id=\"modal-plyr\" data-plyr-provider=\""
    + alias4(((helper = (helper = helpers.provider || (depth0 != null ? depth0.provider : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"provider","hash":{},"data":data,"loc":{"start":{"line":5,"column":47},"end":{"line":5,"column":61}}}) : helper)))
    + "\" data-plyr-embed-id=\""
    + alias4(((helper = (helper = helpers.embedId || (depth0 != null ? depth0.embedId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"embedId","hash":{},"data":data,"loc":{"start":{"line":5,"column":83},"end":{"line":5,"column":96}}}) : helper)))
    + "\"></div>\n    </div>\n  </div>\n</div>";
},"useData":true});