import App from '@/app'
import '@waypoints'

/**
 * Module namespace
 * @required
 * @type {String}
 */
const namespace = 'waypoints'

/**
 * Deinfe module
 * @member App.module
 * @type {Object}
 */
App.module(namespace, {
  /**
   * Module constructor
   * @constructor
   */
  construct() {
    App.$on.newPageReady(x => this.make(x))
       .$on.transitionCompleted(() => this.refresh())
  },

  /**
   * Module data
   * @type {Object}
   */
  data: {
    selector: {
      dataApiInit: '[data-show="waypoint"]',
      visible: '.waypoint--visible',
    },
    className: {
      initial: 'waypoint',
      visible: 'waypoint--visible',
    },
  },

  /**
   * Module methods
   * @type {Object}
   */
  methods: {
    /**
     * Waypoint appear
     * @param  {HTMLElement} element The element to appear
     * @return {HTMLElement}
     */
    in(element) {
      return $(element).addClass(this.className.visible)
    },

    /**
     * Waypoint disappear
     * @param  {HTMLElement} element The element to disappear
     * @return {HTMLElement}
     */
    out(element) {
      return $(element).removeClass(this.className.visible)
    },

    /**
     * Refresh all waypoints
     * @param {*} element 
     */
    refresh(element) {
      if (element) return
      else window.Waypoint.refreshAll()
    },

    destroy(context) {
      return $(this.selector.dataApiInit, context).each(function () {
        console.log( $(this).data('waypoint') )
      })
    },

    restore(context) {
      this.destroy(context)
      this.make(context)
    },

    make(context) {
      const self = this
      return $(this.selector.dataApiInit, context).each(function () {
        $(this).data('waypoint', $(this).addClass(self.className.initial).waypoint({
          offset: '95%',
          handler(dir) {
            dir === 'down' && self.in(this.element)
          }
        })[0])
      })
    }
  },
})