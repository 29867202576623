import App from '@/app'
import '@waypoints'

const NAMESPACE = 'about.index'

App.extend(NAMESPACE, {
  data: {
    className: {
      waypointState: 'in-view--visible'
    },
    selector: {
      waypoint: '.in-view'
    }
  },
  transition: {
    onEnter() {
      this.using('module/navbar').invert(true)
      this.using('module/global').invert(true)
    },
    onEnterCompleted() {
      const _this = this
      $(this.selector.waypoint, this.container).waypoint({
        offset: '90%',
        handler(dir) {
          (dir === 'down') && $(this.element).addClass(_this.className.waypointState)
        }
      })
    },
    onLeave() {
      this.using('module/navbar').invert(false)
      this.using('module/global').invert(false)
    }
  }
})