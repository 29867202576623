import App from '@/app'

/**
 * Module namespace
 * @required
 * @type {String}
 */
const namespace = 'readMore'

/**
 * Deinfe module
 * @member App.module
 * @type {Object}
 */
App.module(namespace, {
  /**
   * Module constructor
   * @constructor
   */
  construct() {
    this.enabled = false

    App.$document.on('sc.window.resize', () => this.resize())

    // When the container has been added to the DOM
    App
      .$on.newPageReady(container => {
        $(container).on('click', this.selector.trigger, event => this.toggle(event.currentTarget))
      })
      .$on.transitionCompleted(container => {
        this.calculate()
      })
  },

  /**
   * Module data
   * @type {Object}
   */
  data: {
    breakpoints: {
      lg: 992
    },
    selector: {
      trigger         : '.js-read-more',
      textElement     : '.js-text-selector',
      bottomEdge      : '.js-bottom-selector',
      offset          : '.js-offset-selector',
      container       : '.js-text-container-selector',
      readMoreWrapper : '.read-more-wrapper'
    },
    calc: {
      isDesktop      : false,
      minHeight      : 50,
      avalibleHeight : 0,
      requiredHeight : 0
    }
  },

  /**
   * Module methods
   * @type {Object}
   */
  methods: {
    toggle(element) {
      // console.group(`${namespace}.toggle`)

      this.open()

      // console.groupEnd()
    },

    hide() {
      const hiddenHeight = this.calc.avalibleHeight > this.calc.minHeight ? this.calc.avalibleHeight : 50

      $(this.selector.container)[0].style.height        = `${hiddenHeight}px`
      $(this.selector.readMoreWrapper)[0].style.display = 'inline-block'
      $(this.selector.offset)[0].style.marginTop        = '0px'  // move to css
      this.enabled = false
    },

    open() {
      $(this.selector.container).animate( { height: `${this.calc.requiredHeight}px` } )    //[0].style.height = `${this.calc.requiredHeight}px`
      $(this.selector.readMoreWrapper)[0].style.display = 'none'
      $(this.selector.offset)[0].style.marginTop        = '30px'  // move to css
      this.enabled = true
    },

    calculate() {
      // console.group(`${namespace}.calculate`)
      if (Boolean($(this.selector.trigger).length )) {
        const textTop        = $(this.selector.textElement)[0].getBoundingClientRect().top
        const realBottom     = $(this.selector.bottomEdge)[0].getBoundingClientRect().bottom
        const offset         = $(this.selector.offset)[0].offsetHeight
        const requiredHeight = $(this.selector.textElement)[0].offsetHeight
        const newBottom      = realBottom - offset
        const avalibleHeight = newBottom - textTop
  
        const w = window.innerWidth;
  
        const ret = {
          isDesktop      : w >= this.breakpoints.lg,
          avalibleHeight : avalibleHeight,
          requiredHeight : requiredHeight,
          minHeight      : this.calc.minHeight
        }
  
        this.calc = {...ret}
  
        if (this.calc.isDesktop && requiredHeight > avalibleHeight) { 
          this.hide()
        } else {
          this.open()
        }
      }
      // console.groupEnd()
    },

    resize() {
      this.calculate()
      
      // console.log(`${namespace}.resize triggered`)
    }
  },
})