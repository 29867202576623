import App from '@/app'

export default {
  /**
   * Plugin mixin extends the module and view layer
   * @type {Object}
   */
  plugins: {
    /**
     * Return the plugin if it exists in scope
     * 
     * @param  {String} param The component/module to lookup
     * @return {Object}
     */
    pluginExists(param) {
      if (this._usage && this._usage[param])
        return this._usage[param].pointer
      else
        return false
    },

    /**
     * Preload method references any plugin found and brings it into scope. Can
     * be useful to use during construct.
     * 
     * Example usage in a view context:
     *   this.use('component/[namespace]', 'module/[namespace]')
     * 
     * @param  {...any} args Multiple arguments of which reference plugins
     * @return {Array} An array of loaded plugins
     */
    use(...args) {
      const arr = []
      args.forEach(param => {
        const plugin = this.using(param)
        plugin && arr.push(plugin)
      })
      return arr
    },

    /**
     * Add a single plugin to the view scope and return it as to
     * start using it off the bat.
     * 
     * Example usage in a view context:
     *   this.using('component/[namespace]')
     * 
     * @param  {String} param The component/module to lookup
     * @return {Object}
     */
    using(param) {
      this._usage = this._usage||{}

      if (this._usage[param])
        return this.pluginExists(param)

      const arr = param.split('/').filter(String)
      if (arr.length > 1) {
        const named = arr[0]
        const descriptor = App.Descriptor[named]
        const id = arr[1]
        if (typeof descriptor !== 'undefined' &&
          App[descriptor] &&
          App[descriptor].hasOwnProperty(id)
        ) {
          this._usage[param] = {
            type: named,
            pointer: App[descriptor][id]
          }
        }
      }
      return this.pluginExists(param)
    }
  },
}