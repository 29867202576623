import App from "@/app";
import "slick-carousel";

const NAME = "slider";
const DATA_KEY = `cue.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const DATA_API_KEY = ".data-api";

const Event = {
  clickDataApi: `click${EVENT_KEY}${DATA_API_KEY}`,
};

const ClassName = {
  navContainer: "slider__nav",
};

const Selector = {
  dataApiInit: '[data-init="slider"]',
  element: ".slider",
  stage: ".slider__stage",
  header: ".slider__header",
  navContainer: ".slider__nav",
  nextArrow: ".slick-next",
  prevArrow: ".slick-prev",
};

const Defaults = {
  prevArrow:
    '<button type="button" class="slick-prev"><span>Previous</span></button>',
  nextArrow:
    '<button type="button" class="slick-next"><span>Next</span></button>',
  mobileFirst: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const optionsPresets = {
  // slider--grid carousels
  related: {
    infinite: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  },
};

class Slider extends App.Component {
  /**
   * Define @public properties
   * @return {Object}
   */
  static get $props() {
    return {
      namespace: DATA_KEY, // namespaced  component
      eventNs: EVENT_KEY, // namespaced  events
      defaults: Defaults, // default component options
      presets: optionsPresets,
    };
  }

  /**
   * Define @public event names
   * @return {Object}
   */
  static get $events() {
    return Event;
  }

  /**
   * Define @public default options
   * @return {Object}
   */
  static get $defaults() {
    return Defaults;
  }

  constructor(element, options) {
    super(element, options, NAME);
    this._enabled = false;
    this.$stage = this.$element
      .find(Selector.stage)
      .on("init", () => (this._enabled = true));
  }

  init() {
    if (this.$options.hasOwnProperty("asNavFor"))
      this.$options.asNavFor += ` ${Selector.stage}`;

    const $header = this.$element.find(Selector.header);
    if ($header.length) {
      $header.append(
        $(document.createElement("nav")).addClass(ClassName.navContainer)
      );
      this.$options.appendArrows = $header.find(Selector.navContainer);
    }

    this.$stage.slick(this.$options);
    return this;
  }

  static stopAll() {
    console.log(`[${NAME}] stopAll.call()`);
  }

  static instance(element, config) {
    element = App.Utils.$(element);
    let data = element.data(DATA_KEY);
    if (!data) {
      data = new Slider(element, config || {});
      element.data(DATA_KEY, data);
    }
    return data;
  }
}

App.$on
  .newPageReady((container) => {
    $(Selector.dataApiInit, container).each(function() {
      Slider.instance($(this), true).init();
    });
  })
  .$on.initStateChange((currentStatus) => {
    const domObj = App.Barba.Pjax.Dom;
    const element = domObj.getContainer();
    if (
      element &&
      domObj &&
      domObj.getNamespace(element) !== currentStatus.namespace
    )
      Slider.stopAll(element);
  });

export default Slider;
