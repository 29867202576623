import App from '@/app'

/**
 * Component namespace
 * @required
 * @type {String}
 */
const namespace = 'navbar'

/**
 * Deinfe component
 * @member App.module
 * @type {Object}
 */
App.module(namespace, {
  /**
   * Component data
   * @type {Object}
   */
  data: {
    className: {
      active: 'active',
      menuState: 'navMenu--open',
      invertState: 'g-header--invert',
      translucentState: 'g-header--translucent'
    },
    selector: {
      header: '.g-header',
      locale: '#localeSwitcher',
      menu: '.g-header__navMenu',
      menuToggle: '.g-header__navToggle',
      menuItem: 'li',
      menuAnchor: 'a',
      mainMenu: '.main',
      activeMenuItem: '.active',
    },
    event: {
      click: `click.${namespace}`
    },
    get $menu() {
      return $(this.selector.menu)
    },
    get $headerHeight() {
      return $(this.selector.header).outerHeight()
    }
  },

  /**
   * Component methods
   * @type {Object}
   */
  methods: {
    /**
     * Toggle mobile menu visibility
     * @return {Element}
     */
    toggle() {
      return $('body').toggleClass(this.className.menuState)
    },

    /**
     * Toggle mobile menu visibility
     * @return {Element}
     */
    show() {
      return this._toggleBodyClass(this.className.menuState, true)
    },

    /**
     * Toggle mobile menu visibility
     * @return {Element}
     */
    hide() {
      return this._toggleBodyClass(this.className.menuState, false)
    },

    /**
    * Set the active menu item by segment param
    * @param  {String} segment 
    * @return {*}
    */
    setBySegment(segment = '') {
      const $element = this.$menu.find(`${this.selector.mainMenu} ${this.selector.menuAnchor}`)
        .filter((i, element) => App.Utils.firstSegment(element.pathname) === segment)

      if ($element.length)
        $element.closest(this.selector.menuItem).addClass(this.className.active)
          .siblings(this.selector.active).removeClass(this.className.active)
      else
        this.$menu.find(`${this.selector.mainMenu} ${this.selector.menuItem}`)
          .filter(this.selector.activeMenuItem).removeClass(this.className.active)

      return this
    },

    /**
     * Change current site to the locale requested
     * @param  {Object} event Event object passed by event handler
     * @return {*}
     */
    _changeLocale(event) {
      event.preventDefault()
      event.stopPropagation() // Hijack event back from Babra
      const currentFragment = App.Utils.firstSegment(window.location.pathname,false)
      const requestFragment = App.Utils.firstSegment(event.target.pathname,false)
      if (currentFragment === 'fr' && requestFragment !== 'fr') {
        window.location.assign(window.location.pathname.substr(3))
      }
      else if (currentFragment !== 'fr' && requestFragment === 'fr') {
        let path = `/fr${window.location.pathname}`
        path = (path[path.length - 1] === '/') ? path.substr(0, path.length - 1) : path
        window.location.assign(path)
      }
      return this
    },

    /**
     * Invert menu
     * @param {Boolean} reset 
     */
    invert(state = false) {
      return this._toggleBodyClass(this.className.invertState, state)
    },

    /**
     * Invert menu
     * @param {Boolean} reset 
     */
    translucent(state = false) {
      return this._toggleBodyClass(this.className.translucentState, state)
    },

    /**
     * Toggle body class
     * @private
     * @param   {String}  className The class to toggle
     * @param   {Boolean} state     Value to determine whether the class should be added or removed
     * @return  {Object}
     */
    _toggleBodyClass(className, state) {
      $('body').toggleClass(className, Boolean(state))
      return this
    },

    _resizeHandler() {
      if (window.matchMedia('(min-width: 576px)').matches) {
        this.hide()
      }
    }
  },

  /**
   * Component constructor
   * @constructor
   */
  construct() {
    App.$window.on('resize', App.Utils.throttle(() => this._resizeHandler(), 300))
    // Toggle mobile menu
    App.$document.on(this.event.click, this.selector.menuToggle, () => this.toggle())
    // Set active item on pushState
    App.$on.initStateChange(() => {
      this.hide()
      const a = this.setBySegment(App.Utils.firstSegment(window.location.pathname))
    })
    // Page locale switcher
    $(this.selector.locale).on(this.event.click, this.selector.menuAnchor, e => this._changeLocale(e))
  }
})