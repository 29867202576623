import BaseUtils from '@/utils'

export default class BaseComponent {
  constructor(element, options, name) {
    this.$name = name
    this.$element = BaseUtils.$(element)
    this.$options = this._getConfig(options)
  }

  _getConfig(config) {
    if (this.constructor.$defaults) {
      config = {
        ...this.constructor.$defaults,
        ...this.$element.data(),
        ...config
      }
    }
    else {
      config = {
        ...this.$element.data(),
        ...config
      }
    }
    if (this.constructor.$types) {
      BaseUtils.typeCheckConfig(
        this.$name,
        config,
        this.constructor.$types
      )
    }
    return config
  }
}