import App from '@/app'

/**
 * playbuttons must have a class of player-playbutton and a datarrtibute of playerId
 * */


/**
 * Language stuff
 * */
const lang = {
    en: {
        add_queue: 'Added to the queue'
    },
    fr: {}
};

lang.fr = lang.en;

const t = key => {
    const _lang = !App.isPrimarySite() ? 'fr' : 'en';
    return lang[_lang][key] ? lang[_lang][key] : '';
};


/**
 * Globals
 * */
let playQue = [];

/**
 * Player height
 * */

let config = {
    expanded         : false,
    queListOpen      : false,
    playing          : false,
    nowPlayingTrack  : '',
    nowPlayingArtist : '',
    channel          : '',
    id               : 0,
    trackDuration    : 0,
    timeMode         : false
};

let audio;
let element;

//globaly required elements
let playbarElm;
let timeElm;
let top;
let playbarContainer;
let nowPlaying;
let toolTip;

let timeFrameId;
let timer;
let hover;

const setState = next => {
    config = {
        ...config,
        ...next
    };
    updateState()
};

/**
 * Update State
 * */
const updateState = () => {
    //retrigger que list incase the state update added new tracks
    expand();
    playButton();

    playQueExists();

    updateNowPlaying();

    destroyQue();
    openCloseQueList();

    pageIcons();
};

/**
 * Player controls
 * */
const fadeAndPause = () => {
    let fadeFrameId;

    const fade = () => {
        // To Do: Set a timing...

        const speed = 0.02;
        audio.volume = audio.volume - speed;
        if (audio.volume <= speed) {
            cancelAnimationFrame(fadeFrameId);
            audio.pause();
            audio.volume = 1;
        } else {
            fadeFrameId = requestAnimationFrame(fade)
        }
    };

    fade();

    cancelAnimationFrame(timeFrameId);

    setState({
        playing: false
    });
};

const playResume = () => {
    audio.play();
    currentTimeUpdateor();

    setState({
        playing: true,
        expanded: true
    });
};

const togglePlay = () => {
    if (audio.src !== '') {
        config.playing ? fadeAndPause() : playResume()
    }
};

/**
 * Event handlers
 * */
const changeTimeHandler = e => {
    const box = playbarContainer.getBoundingClientRect();
    const offset = e.screenX - box.x;
    const w = box.width;

    audio.currentTime = (offset / w) * audio.duration;

    currentTimeUpdateor()
};

const queListHandler = () => {
    setState({
        queListOpen: !config.queListOpen,
    });
};

const mouseLeaveHandle = e => {
    if ($.isFunction(e.target.removeEventListener))
        e.target.removeEventListener("mouseleave", mouseLeaveHandle, false);

    clearTimeout(hover)
};

const mouseEnterHandle = e => {
    e.target.addEventListener("mouseleave", mouseLeaveHandle, false);
    hover = setTimeout(() => {
        setState({
            expanded: true
        });
    }, 1000);
};

const changeTimeMode = () => {
    setState({
        timeMode: !config.timeMode
    });
};

const expandToggle = () => {
    setState({
        expanded: !config.expanded
    });
};

/******
 * Instantiator
 ******/
const goAndInitiate = () =>  {
    goAndMakePlayer();

    // Create and add player to body
    element = goAndCreateView();

    //load que list from session storage session storage
    //do this after everything has loaded
    if (sessionStorage.getItem("playQue")) {
        // Restore the que
        playQue = JSON.parse(sessionStorage.getItem("playQue"));
        loadPlay(false)
    }
};

const goAndMakePlayer = () => {
    audio = makeObject('audio', 'player-audio');

    audio.onended = () => {
        loadPlay(true)
    };

};

/**
 * Dom node generator
 * */
const makeObject = (type, name, parent) => {
    const object = document.createElement(`${type}`);
    object.classList.add(`player-${name}`);

    if (parent !== undefined) {
        //add to parent
        parent.appendChild(object)
    } else {
        //add to body
        const body = document.querySelector("body");
        body.appendChild(object)
    }

    return object;
};

/**
 * Build Dom
 * */
const goAndCreateView = () => {
    //Build Player
    const containingDiv = makeObject('div', 'containingDiv');

    //Div containing the play/pause icon
    const alwaysVisibleHalf = makeObject('div', 'alwaysVisibleHalf', containingDiv);

    const expandButton = makeObject('div', 'expandButton', alwaysVisibleHalf);
    expandButton.addEventListener('click', expandToggle, false);

    const playPause = makeObject('i', 'playPause', alwaysVisibleHalf);
    playPause.classList.add('icon-play'); //add a play icon
    playPause.addEventListener('mouseenter', mouseEnterHandle, false);
    playPause.addEventListener('click', mobilePlayFilter, false);

    //Div containing the playbar and metadata
    const sometimesVisibleHalf = makeObject('div', 'sometimesVisibleHalf', containingDiv);

    //Que expand button
    top = makeObject('div', 'top', sometimesVisibleHalf);
    const bottom = makeObject('div', 'bottom', sometimesVisibleHalf);

    nowPlaying = makeObject('div', 'nowPlaying', sometimesVisibleHalf);
    nowPlaying.classList.add("text-truncate");

    //top
    top.classList.add("icon-arrow-up");
    top.addEventListener("click", queListHandler, false);

    // bottom
    playbarContainer = makeObject('div', 'playbarContainer', bottom);
    playbarContainer.addEventListener("click", changeTimeHandler, false);
    playbarElm       = makeObject('div', 'playbar', playbarContainer);

    timeElm           = makeObject('div', 'time', bottom);
    timeElm.innerText = ``;
    timeElm.addEventListener("click", changeTimeMode, false);

    toolTip           = makeObject('div', 'toolTip');
    toolTip.innerText = t('add_queue');

    //return element
    return containingDiv;
};

/**
 * Fetch and update data
 * */
const getNewData = id => {
    fetch(`/api/v1/player/${id}`)
        .then(response => {
            response.json()
                .then(data => {
                    updateQue(data.data[0], id);
                })
                .then(() => {
                    //trigger player
                    config.playing ? qued() : loadPlay(true)
                })
        })
        .catch(err => {
            console.log('Fetch Error', err);
        });
};

const updateQue = (data, id) => {
    //add request id to objects
    data.forEach((dataItem, i) => {
        data[i] = {
            ...dataItem,
            id: id
        }
    });

    // update playQue
    if (data[0] !== 'playlists') {
      playQue = data
      loadPlay(true)
      setState({
        queListOpen: true,
      });
      window.setTimeout(() => {
        setState({
          queListOpen: false,
        })
      }, 3000,);
    } else {
      playQue.push(...data)
    } 
      

    //remove duplicates
    playQue = dedupe(playQue);

    //trigger re render with new data
    updateState();

    // Add to session storage
    sessionStorage.setItem("playQue", JSON.stringify(playQue));
};

// remove duplicates in array
const dedupe = arr => {
    //not sure stringify is the best way to do this tbh
    const uqid = new Set(arr.map(e => JSON.stringify(e)));
    return Array.from(uqid).map(e => JSON.parse(e));
};

const mobilePlayFilter = () => {
    if (!App.isMobileBrowser() || config.expanded === true) {
        togglePlay()
    } else {
        setState({
            expanded: true
        })
    }
};

/**
 * Play methods
 * */
const loadPlay = play => {
    if (playQue.length !== 0) { //check for existence of another element in the array
        const nextObj = playQue.shift();
        sessionStorage.setItem("playQue", JSON.stringify(playQue));

        audio.src = nextObj.url;

        if (play) { playResume() }

        setState({
            nowPlayingTrack: nextObj.trackName,
            nowPlayingArtist: nextObj.artistName,
            channel: nextObj.channel,
            id: nextObj.id,
        });
    } else { //if no next item reset the player
        setState({
            expanded: false,
            playing: false,
            nowPlayingTrack: '',
            nowPlayingArtist: '',
            channel: '',
            id: 0,
            trackDuration: 0
        });
    }
};

const queShiftPlay = e => {
    //shift to front of que and play
    const item = playQue[e.target.dataset.queid];
    removeQueItem(e.target.dataset.queid);
    playQue.unshift(item);
    sessionStorage.setItem("playQue", JSON.stringify(playQue));
    loadPlay(true)
};

const qued = () => {
    if (!config.queListOpen) {
        toolTip.style.opacity = 1;
        setTimeout(() => {
            toolTip.style.opacity = 0;
        }, 3000);
    }
};

/**************
 * State updaters,
 *************/
/**
 * Expand/Hide the player
 * */
const expand = () => {
    if (config.expanded) {
        element.classList.add('player-containingDiv-expanded');

        leftThePlayer();
        element.addEventListener('mouseleave', leftThePlayer, false);
    } else {
        if ($.isFunction(element.removeEventListener))
            element.removeEventListener('mouseleave', leftThePlayer, false);

        element.classList.remove('player-containingDiv-expanded');
        config.queListOpen = false;
        destroyQue(); //this will trigger again in the update state function but the functionality is more explicit stated here
    }
};

const leftThePlayer = () => {
    //Time out to collapse
    timer = setTimeout(() => {
        if (!isHovering(element) && !config.queListOpen) {
            setState({
                expanded: false
            });
        }
    }, 3000);

    element.addEventListener('mouseenter', clearTimer, false);
};

//this exists so event handlers are not referencing anonymous functions and can be removed dynamically
const clearTimer = () => {
    clearTimeout(timer);
};

/**
 * Play pause button
 * */
const playButton = () => {
    const playPause = document.querySelector('.player-playPause');

    config.playing ? playPause.classList.remove('icon-play') : playPause.classList.add('icon-play');
    config.playing ? playPause.classList.add('icon-pause') : playPause.classList.remove('icon-pause');
};

const queCloseHandle = e => {
    removeQueItem(e.target.dataset.queid);
};

const removeQueItem = i => {
    playQue.splice(i, 1);
    sessionStorage.setItem("playQue", JSON.stringify(playQue));
    updateState()
};

const openCloseQueList = () => {
    //rotate button
    config.queListOpen ? top.style.transform = 'rotate(180deg)' : top.style.transform = 'rotate(0deg)';

    if (config.queListOpen) { //add
        //create container
        const queContainer = makeObject('div', 'queContainer');
        const queList = makeObject('ul', 'queList', queContainer);

        playQue.forEach((item, i) => {
            makeQueItem(item, i, queList);
        });
        // makeQueHeader()

    } else { //remove
        destroyQue();
    }
};

const makeQueItem = (item, i, parent) => {
    const h = 50;
    //create que item
    const queItem = makeObject('li', 'queItem', parent);
    const quePlayItem = makeObject('div', 'quePlayItem', queItem);
    quePlayItem.classList.add('icon-play');
    quePlayItem.setAttribute('data-playerqueid', item.id);
    quePlayItem.setAttribute('data-queid', i);
    quePlayItem.addEventListener("click", queShiftPlay, false);

    const queText = makeObject('div', 'queText', queItem);
    queText.innerText = `${item.artistName} - ${item.trackName}`

    const queCloseItem = makeObject('div', 'queCloseItem', queItem);
    queCloseItem.classList.add('icon-x');
    queCloseItem.setAttribute('data-queid', i);
    queCloseItem.addEventListener("click", queCloseHandle, false);

    //position
    queItem.style.height = `${h}px`;
    // queItem.style.transform = `translateY(-${h * i}px)`;
};

const playQueExists = () => {
    (playQue.length !== 0) ? top.style.display = 'unset' : top.style.display = 'none'
};

// const makeQueHeader = () => {
//     const h = 50;
//
//     const queHeader = makeObject('div', 'queHeader');
//
//     const queHeaderCloseItem = makeObject('div', 'queHeaderCloseItem', queHeader);
//     queHeaderCloseItem.classList.add('icon-x');
//
//     //position
//     queHeader.style.transform = `translateY(-${h * playQue.length}px)`;
// };

const destroyQue = ()  => {
    // const que = document.querySelectorAll('.player-queItem');
    // que.forEach(item => {
    //     item.remove()
    // })
    const que = document.querySelector('.player-queContainer');
    if (Boolean(que)) {
        que.remove();
    }
};

const updateNowPlaying = () => {
    nowPlaying.innerText = (config.nowPlayingArtist === "" && config.nowPlayingTrack === "" ) ? `` : `${config.nowPlayingArtist} - ${config.nowPlayingTrack}`
};

//code to handle play icons within the page and provide intuitive control
const pageIcons = () => {
    const icons = document.querySelectorAll(".icon-selector");

    icons.forEach(icon => {
        icon.classList.remove("icon-play");
        icon.classList.remove("icon-pause");

        if (icon.dataset.playerid === config.id && config.playing) {
            icon.classList.add("icon-pause");
        } else {
            icon.classList.add("icon-play");
        }
    })
};

/**
 * Click handle
 * */
const clickHandle = e => {
    //code to handle play icons within the page and provide intuitive control
    if (e.target.dataset.playerid === config.id) {
        togglePlay()
    } else { // normal methods
        //get data
        getNewData(e.target.dataset.playerid);

        //Removed this line from the promise as triggering play in an asynchronous call stack is illegal in safari
        //Play must be triggered directly in the same call stack
        config.playing ? qued() : loadPlay(true);

        //update config
        setState({
            expanded: true
        });
    }
};

/**
 * Animationframe for play bar
 *
 * call on play
 * cancel on pause
 * */
const currentTimeUpdateor = () => {
    const percent = (audio.currentTime / audio.duration) * 100;

    playbarElm.style.width = `${percent}%`;

    if (!config.timeMode) {
        timeElm.innerText = readableDuration(audio.currentTime);
    } else {
        timeElm.innerText = readableDuration(audio.duration - audio.currentTime);
    }

    timeFrameId = requestAnimationFrame(currentTimeUpdateor)
};

const readableDuration = seconds => {
    let sec;
    let min;
    sec = Math.floor( seconds );
    min = Math.floor( sec / 60 );
    min = min >= 10 ? min : '0' + min;
    sec = Math.floor( sec % 60 );
    sec = sec >= 10 ? sec : '0' + sec;
    return min + ':' + sec;
};

// Do things on the document, body or window if not IE
if (!App.Utils.detectIE()) {

  /**
   * On dom ready
   * */
  window.addEventListener('load', goAndInitiate);

  /**
   * On page ready
   * */
  App.$on.newPageReady(() => {
      //find play elements
      const playbuttons = document.querySelectorAll(".player-playbutton");

      pageIcons();

      //trigger play event
      playbuttons.forEach(playbutton => {
          playbutton.addEventListener('click', clickHandle, false);
      })
  });

  window.addEventListener('scroll', () => {
      if (App.isMobileBrowser())
          setState({expanded: false})
  }, false);
}

let cursor = {x: 0, y: 0};
document.onmousemove = e =>{
    cursor.x = e.x;
    cursor.y = e.y;
};

const isHovering = ele => {
    const rect = ele.getBoundingClientRect();
    return (cursor.x > rect.left && cursor.x < rect.right && cursor.y > rect.top && cursor.y < rect.bottom)
};

export default {}