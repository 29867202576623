import App from "@/app";
import { Dropdown } from "@/components";

const NAMESPACE = "artist.index";

const Selector = {
  tiles: ".tile--artist",
  filterItem: "[data-filter]",
  resetFilter: '[data-filter="reset"]',
  filterScope: "#filterCategory",
};

const ClassName = {
  tileFilter: "tile--animateIn",
};

App.extend(NAMESPACE, {
  data: {
    get $tiles() {
      return $(Selector.tiles, this.container);
    },
    get activeFilter() {
      return this._activeFilter;
    },
    // setter and notifier
    set activeFilter(val) {
      this._activeFilter = val;
      val !== null && this.updateFilterState();
    },
  },
  methods: {
    handleClick(event) {
      event.preventDefault();
      const target = $(event.currentTarget);
      const delegateTarget = event.delegateTarget;
      this.activeFilter = target.data("filter");
      this.applyFilter(target, delegateTarget);
    },
    checkQueryString() {
      // get the query string
      const queryString = window.location.search;
      if (queryString) {
        // if it exists turn into params
        const urlParams = new URLSearchParams(queryString);
        const filter = urlParams.get("filter");
        if (filter !== "") {
          // set the filter
          this.activeFilter = filter;
        
          // reset the other items
          $(".nav-dropdown__item").removeClass('active');
          // add the active class
          $(".nav-dropdown__item[data-filter=" + filter  + "]").addClass('active');
          
          // set the dropdown to active
          const target = $(".nav").find("[data-filter='" + filter + "']");
          // set the parent manually
          const delegateTarget = $("#filterCategory");
          // div#filterCategory.toolbar-dropdown
          // execute function
          this.applyFilter(target, delegateTarget);
        }
      }
    },
    applyFilter(target, delegateTarget) {
      if (this.activeFilter !== "reset") {
        history.pushState(
          {},
          this.activeFilter,
          "/artists?filter=" + this.activeFilter + ""
        );
      } else {
        history.pushState({}, this.activeFilter, "/catalogues");
      }

      $(Selector.resetFilter, delegateTarget).css(
        "display",
        this.activeFilter !== "reset" ? "block" : "none"
      );
      // filter active state
      $(`.active${Selector.filterItem}`, delegateTarget).removeClass("active");
      this.activeFilter !== "reset" && target.addClass("active");
    },
    updateFilterState() {
      let filterDropdown;
      if (
        (filterDropdown = Dropdown.getInstance(
          $(`[data-dropdown="${Selector.filterScope}"]`).parent()
        )) &&
        filterDropdown.length
      ) {
        // hide the dropdown
        filterDropdown.data(Dropdown.$props.namespace).hide();
      }
      // toggle tiles being shown/hidden
      if (this.activeFilter === "reset") {
        // reset shows all tiles
        this.$tiles.removeClass(ClassName.tileFilter).show();
      } else {
        // filter tiles
        this.$tiles
          .removeClass(ClassName.tileFilter)
          .not(`[data-filter-category="${this.activeFilter}"]`)
          .hide()
          .end()
          .filter(`[data-filter-category="${this.activeFilter}"]`)
          .addClass(ClassName.tileFilter)
          .show();
      }
      // scroll to top if offset
      window.scrollY && window.scrollTo(0, 0);
      this.using("module/waypoints").refresh();
    },
  },
  transition: {
    onEnterCompleted() {
      $(Selector.filterScope).on("click", Selector.filterItem, (event) =>
        this.handleClick(event)
      );
  
      $(".dropdown__select--category").on("change", (event) => {
        this.activeFilter = event.target.value;
        // // set the dropdown to active
        const target = $(".nav").find(
          "[data-filter-category='" + event.target.value + "']"
        );
        // set the parent manually
        const delegateTarget = $("#filterCategory");
        // execute function
        this.applyFilter(target, delegateTarget);
      });
      $(".dropdown__select--artists").on("change", (event) => {
        // // set the dropdown to active
        window.location.href = event.target.value;
      });

      this.checkQueryString();
    },
    onLeave() {
      this.activeFilter = null;
      $(".nav-dropdown__item").removeClass('active');
    },
  },
});
