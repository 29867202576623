import App from "@/app";
import { Slider, Dropdown } from "@/components";
import "jquery-match-height";

const NAMESPACE = "catalogues.index";

const Selector = {
  tiles: ".tile",
  filterItem: "[data-filter-category]",
  filterScope: "#filterCategory",
  resetFilter: '[data-filter-category = "reset"]',
  matchHeight: ".catalogues__group  .catalogue__inner",
  activeState: ".active",
  visibleWaypoint: ".waypoint--visible",
};

const ClassName = {
  activeState: "active",
  visibleWaypoint: "waypoint--visible",
};

App.extend(NAMESPACE, {
  data: {
    get $tiles() {
      return $(Selector.tiles, this.container);
    },
    get activeFilter() {
      return this._activeFilter;
    },
    // setter and notifier
    set activeFilter(val) {
      this._activeFilter = val;
      val !== null && this.updateFilterState();
    },
  },
  methods: {
    handleClick(event) {
      event.preventDefault();
      const target = $(event.currentTarget);
      const delegateTarget = event.delegateTarget;
      this.activeFilter = target.data("filter-category");
      this.applyFilter(target, delegateTarget);
    },
    checkQueryString() {
      // get the query string
      const queryString = window.location.search;
      if (queryString) {
        // if it exists turn into params
        const urlParams = new URLSearchParams(queryString);
        const filter = urlParams.get("filter");
        if (filter !== "") {
          // set the filter
          this.activeFilter = filter;
           // reset the other items
           $(".nav-dropdown__item").removeClass('active');
           // add the active class
           $(".nav-dropdown__item[data-filter=" + filter  + "]").addClass('active');
          // // set the dropdown to active
          const target = $(".nav").find(
            "[data-filter-category='" + filter + "']"
          );
          // set the parent manually
          const delegateTarget = $("#filterCategory");
          // execute function
          this.applyFilter(target, delegateTarget);
        }
      }
    },
    applyFilter(target, delegateTarget) {
      // toggle reset filter
      if (this.activeFilter !== "reset") {
        history.pushState(
          {},
          this.activeFilter,
          "/catalogues?filter=" + this.activeFilter + ""
        );
      } else {
        history.pushState({}, this.activeFilter, "/catalogues");
      }
      $(Selector.resetFilter, delegateTarget).css(
        "display",
        this.activeFilter !== "reset" ? "block" : "none"
      );
      // filter active state
      $(`.active${Selector.filterItem}`, delegateTarget).removeClass("active");
      this.activeFilter !== "reset" && target.addClass("active");
      var count = 0;
      // loop through catalogues group
      $(".catalogues__group").each(function() {
        // show every parent
        $(this)
          .parent()
          .addClass("filtered")
          .show();
        // loop through group children to count for hidden ones
        $(this)
          .children()
          .each(function() {
            // count if hidden
            $(this).is(":hidden") && count++;
          });
        // console.log(`parent() ${$(this).parent()}: count: ${count} `,$(this).children().length)
        $(this).children().length === count
          ? $(this)
              .parent()
              .hide()
          : $(this)
              .parent()
              .show();
        count = 0;
      });
    },
    updateFilterState() {
      let filterDropdown;
      if (
        (filterDropdown = Dropdown.getInstance(
          $(`[data-dropdown="${Selector.filterScope}"]`).parent()
        )) &&
        filterDropdown.length
      ) {
        // hide the dropdown
        filterDropdown.data(Dropdown.$props.namespace).hide();
      }
      // toggle tiles being shown/hidden
      if (this.activeFilter === "reset") {
        // reset shows all tiles
        this.$tiles.removeClass(ClassName.tileFilter + " hidden filtered");
      } else {
        // filter tiles
        this.$tiles
          .removeClass(ClassName.tileFilter + " filtered")
          .not(`[data-filter-category="${this.activeFilter}"]`)
          .addClass("hidden")
          .end()
          .filter(`[data-filter-category="${this.activeFilter}"]`)
          .addClass(ClassName.tileFilter + " filtered")
          .removeClass("hidden");
      }
      // scroll to top if offset
      window.scrollY && window.scrollTo(0, 0);
      this.using("module/waypoints").refresh();
    },
    slidesToShow(slidesToShow, totalSlides) {
      return totalSlides < slidesToShow ? totalSlides : slidesToShow;
    },
  },
  transition: {
    onEnter() {},
    onEnterCompleted() {
      $(Selector.filterScope).on("click", Selector.filterItem, (event) =>
        this.handleClick(event)
      );
      $(".dropdown__select").on("change", (event) => {
        this.activeFilter = event.target.value;
        // // set the dropdown to active
        const target = $(".nav").find(
          "[data-filter-category='" + event.target.value + "']"
        );
        // set the parent manually
        const delegateTarget = $("#filterCategory");
        // execute function
        this.applyFilter(target, delegateTarget);
      });
      console.log("remove query trigger 1.5");
      this.checkQueryString();
      // Handle Slick
      const that = this;
      const shows = $("#tv-shows").data("show-items");
      console.log("shows", shows);
      if (shows >= 1) {
        console.log("initialising slider");
        // init featured slider
        Slider.instance($("#tvShowsMedia"), {
          arrows: false,
          autoplay: true,
          pauseOnHover: false,
          autoplaySpeed: 4000,
          asNavFor: "#tvShowsMediaNav",
        }).init();

        const $sliderNav = $("#tvShowsMediaNav");
        const slideCount = $sliderNav.find(".slider__item").length;
        Slider.instance($sliderNav, {
          arrows: false,
          asNavFor: "#tvShowsMedia",
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 576,
              settings: {
                slidesToShow: that.slidesToShow(2, slideCount),
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: that.slidesToShow(3, slideCount),
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: that.slidesToShow(4, slideCount),
              },
            },
          ],
        }).init();
      }
    },
    onLeave() {
      this.activeFilter = { value: null };
      $(".nav-dropdown__item").removeClass('active');
    },
  },
});
