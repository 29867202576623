import App from '@/app'
import Plyr from 'plyr'
import VideoModalPartial from '@template/videoModal.hbs'

/**
 * Module namespace
 * @required
 * @type {String}
 */
const NAMESPACE = 'videoPlayer'

const Event = {
  modalTrigger: `click.${NAMESPACE}`
}

const Selector = {
  modalContainer: '#modalContainer',
  modalDismiss: '[data-dismiss="modal"]',
  modalDataApi: '[data-video-player="modal"]',
  inlineDataApi: '[data-video-player="inline"]'
}

/**
 * Deinfe module
 * @member App.module
 * @type {Object}
 */
App.module(NAMESPACE, {
  /**
   * Module constructor
   * @constructor
   */
  construct() {
    // transition support
    this.transitionEvent = App.Utils.transitionEnd()

    App.$on.newPageReady(container => {
      $(Selector.inlineDataApi).each((i, elm) => this.toggleInline(elm))
      $(container).on(Event.modalTrigger, Selector.modalDataApi, event => this.toggleModal(event))
    })
  },

  /**
   * Module methods
   * @type {Object}
   */
  methods: {
    toggleInline(elm) {
      return new Plyr(elm)
    },

    toggleModal(event) {
      event.preventDefault()
      this.createModal({
        url: event.currentTarget.href,
        relatedTarget: event.currentTarget
      })
    },

    createModal({ url }) {
      const $modal = $(
        VideoModalPartial({
          embedId: url,
          provider: 'youtube'
        })
      ).appendTo(document.body)

      $modal.fadeIn(500, () => {
        $modal.addClass('active')
        this.toggleInline('#modal-plyr')
      })

      this.using('module/global').toggleScroll(true)
      $(Selector.modalDismiss).on('click', event => this.destroyModal(event))
    },

    destroyModal() {
      $(Selector.modalContainer).fadeOut(500, function() {
        $(this).remove()
      })

      this.using('module/global').toggleScroll(false)
    }
  }
})